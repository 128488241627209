<template>

<!-- 校园管理 教师管理页面 修改-->
  <div class="teacherManageAdd-container">
    <div class="teacjer-info">
      <el-form label-position="left" label-width="120px" ref="tacherFromRef" :model="tacherFrom" :rules="tacherFromSref">
        <el-form-item label="名称" prop="sysName">
          <el-input   v-model="tacherFrom.sysName"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input maxlength="11" v-model="tacherFrom.phone"></el-input>
        </el-form-item>
      <el-form-item class="button-section">
        <el-button type="primary" @click="saveData">
        编辑
      </el-button>
      <router-link :to="{name: 'managementTeacherManage'}">
        <el-button>
          取消
        </el-button>
      </router-link>
      </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import storage from '@/globals/storage/index.js'
import teacherService from '@/globals/service/management/teacher.js'
export default {
  data () {
    return {
      tacherFromSref: {
        sysName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { required: true, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
            trigger: 'blur'
          }
        ]
      },
      tacherFrom: {
        // 手机
        phone: '',
        // 权限
        roleType: 7,
        // // 学校id
        // schoolId: '2'
        // 老师昵称
        sysName: ''
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      // this.tacherFrom = this.$route.params.item

      Object.assign(this.tacherFrom,this.$route.params.item)
       this.tacherFrom.sysName = this.tacherFrom.teacherName


      console.log(this.tacherFrom.teacherName);
    },
    saveData () {
      this.$refs.tacherFromRef.validate(valid => {
        if (valid) {
          const tacherFrom = this.tacherFrom
          tacherFrom.roleType = 7
          tacherFrom.schoolId = storage.get('schoolId')
          tacherFrom.id = tacherFrom.teacherId
          teacherService.editTeacher(tacherFrom).then(res => {
            this.$message({
              type: 'success',
              message: '编辑成功!'
            })
            this.$router.push({ name: 'managementTeacherManage' })

            // this.$confirm(`账号: ${res.data.phone}; 密码: ${res.data.password}`, '请记住你的账号密码', {
            //   confirmButtonText: '确定',
            //   type: 'success'
            // }).then(() => {
            //   this.$router.push({ name: 'managementTeacherManage' })
            // })
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.teacherManageAdd-container{
  background-color: #fff;
  min-height: 100%;
  width: 100%;
  padding: 80px 40px 20px 61px;
  .teacjer-info{
    max-width: 500px;
    min-width: 400px;
    color: #333;
    .el-col-2{
      text-align: center;
    }
    .el-select{
      width: 100%;
    }
  }
  .button-section{
    .router-link-active{
      margin-left: 20px;
    }
  }
}
</style>
